<template>
  <div class="tile is-parent is-3">
    <article class="tile is-child box notification has-background-link-light">
      <p class="title">{{ item.name }}</p>
      <p class="subtitle" style="font-size: 16px">{{ item.shortDescription }}</p>
        <img :src="item.image" v-bind:alt="item.name" style="object-fit: contain; height: 200px">
      <div class="w-card-content">
        <p>
          <b-icon icon="currency-usd" size="is-small"></b-icon>
          <span v-if="item.caution < 0"> Aucune caution</span>
          <span v-else> Caution: <b>{{ item.cautionText.replace('{price}', item.caution) }}</b></span>
        </p>
        <p>
          <b-icon icon="card-account-details" size="is-small"></b-icon>
          <span v-if="item.memberCard"> La carte membre est nécessaire</span>
          <span v-else> La carte membre n'est pas nécessaire</span>
        </p>
      </div>

      <p class="item-price">{{ item.priceText.replace('{price}', item.price) }}</p>

      <span class="tag is-danger is-medium" v-if="item.stock === 0">Rupture de stock</span>

      <div>
        <b-button tag="router-link" icon-left="eye-outline" style="margin-top: 8px" :to="'/categories/' + category.slug + '/' + item.slug">
          Afficher les détails
        </b-button>
      </div>

    </article>

  </div>
</template>

<script>
export default {
  name: "CategoryItem",
  components: {},
  props: {
    item: {},
    category:{}
  }
}
</script>

<style scoped>
.w-card-content {
  text-align: left;
  margin-top: 5px;
}

.item-price {
  margin: 5px;
  font-size: 36px;
}
</style>