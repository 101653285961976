<template>
  <div>

    <div class="w-title" style="margin-bottom: 5px;">

      <nav class="breadcrumb is-small" aria-label="breadcrumbs">
        <ul>
          <li><router-link to="/">Accueil</router-link></li>
          <li><router-link to="/categories">Catégories</router-link></li>
          <li class="is-active"><router-link :to="'/' + category.slug" aria-current="page">{{ category.name }}</router-link></li>
        </ul>
      </nav>

      <h1 class="title">{{ category.name }}</h1>
      <p>{{ category.description }}</p>
    </div>

    <div class="tile is-ancestor" style="margin:20px;padding-left: 10px; padding-right: 10px" v-for="(items, index) in sortedItems" :key="index">
      <CategoryItem v-for="(item, index) in items" :key="index" :category="category" :item="item"></CategoryItem>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import CategoryItem from "@/components/CategoryItem";

export default {
  name: "Category",
  components: {CategoryItem},
  data() {
    return {
      category: {},
      sortedItems: []
    }
  },
  computed: {
    ...mapGetters("data", ["getCategory"])
  },
  mounted() {
    this.loadCategory()
  },
  beforeRouteUpdate(to, from, next) {
    next()
    this.loadCategory()
  },
  methods: {
    loadCategory() {
      let category = this.getCategory(this.$route.params.slug)

      if (!category) {
        this.$router.push('/categories')
        this.$buefy.notification.open({
          duration: 5000,
          message: `La catégorie n'a pas été trouvée`,
          position: 'is-top',
          type: 'is-danger',
          hasIcon: true
        })
        return;
      }

      this.category = category

      if(category.items === undefined) {
        this.sortedItems = []
        return;
      }


      let sortedItems = []
      let tempArr = []
      let y = 0
      let catItems = this.category.items.filter(i => i.stock >= 0)
      let length = category.items.length
      let items = catItems.sort((a, b) => a.name.localeCompare(b.name))

      for (let i = 1; i <= length; i++) {
        tempArr.push(items[i - 1])

        // 4 = card size
        if(i % 4 === 0) {
          sortedItems[y] = tempArr
          tempArr = []
          y++
        }
      }

      if(tempArr.length !== 0)
        sortedItems[y] = tempArr

      this.sortedItems = sortedItems
    }
  }
}
</script>

<style scoped>
</style>